const requestUrl = {
    bot: { // 用户
        getBotList: '/api/bot/list', // 获取机器人列表 GET
        getSkillList: '/api/skill/list', //获取知识库列表GET

    },
    knowledgeBase: {
        knowledgeBaseTree: "/knowledge-api/knowledgeBase/selectKnowledgeBaseTreeTotal",
        deleteKnowledge: "/knowledge-api/knowledgeBase/knowledgeBaseStructureDelete",
        saveKnowledge: "/knowledge-api/knowledgeBase/knowledgeBaseStructureSave",
        updateKnowledge: "/knowledge-api/knowledgeBase/knowledgeBaseStructureUpdate",
        moveKnowledge: "/knowledge-api/knowledgeBase/updateKnowledgeBaseStructureOrderNum",
        permissionSet: "/knowledge-api/knowledgeBase/permissionSetting",
        permissionSetV2: "/knowledge-api/knowledgeBase/v2/permissionSetting",
        getPermissionName: "/portal-api/account/queryName",
        knowledgeBaseTreeLazy: "/knowledge-api/knowledgeBase/selectKnowledgeBaseStructureTree",
        saveLocalUploadFile: "/knowledge-api/knowledgeBase/saveLocalUploadFile",
        getFolderAndFile: "/knowledge-api/knowledgeBase/selectTreeFolderAndFile",
        moveDeleteFolder: "/knowledge-api/knowledgeBase/knowledgeBaseStructureDelete",
        saveBatch: "/knowledge-api/knowledgeBase/knowledgeBaseStructureSaveBatch",
        moveToNewHome: "/knowledge-api/knowledgeBase/moveToNewHome",
        fileSync: "/knowledge-api/knowledgeBase/fileSync",
        getKnowledgeBaseById: "/knowledge-api/knowledgeBase/selectKnowledgeBaseById",
        aiLearnProgress: '/knowledge-api/knowledgeBase/selectAiLearnProgress',
        clientSelectKnowledgeBaseStructureTree: '/knowledge-api/knowledgeBase/clientSelectKnowledgeBaseStructureTree',
        clientSelectTreeFolderAndFile: '/knowledge-api/knowledgeBase/clientSelectTreeFolderAndFile',
        relearnKnowledge: '/knowledge-api/knowledge/rebuild-index/', // POST 知识重新学习
        batchPermissionSetting: "/knowledge-api/knowledgeBase/batchPermissionSetting", // 批量权限设置（POST）
        delCheckFolder: "/knowledge-api/knowledgeBase/check-folder", // 删除文件夹时的校验
        clientSelectFileTags: "/knowledge-api/knowledgeBase/selectFileTags",
        publishKnowledge: "/knowledge-api/knowledgeBase/publish",
        archiveKnowledge: "/knowledge-api/knowledgeBase/archive",
        batchPublishOrArchive:"/knowledge-api/knowledgeBase/batchPublishOrArchive"
    },
    channel: {
        getAccessChannel: "/knowledge-api/channel/getAccessChannel",
        channelAccess: "/knowledge-api/channel/channelAccess",
        channelUnBInd: "/knowledge-api/channel/channelUnbind",
        checkAccess: "/knowledge-api/channel/checkAccessChannel",
        autoSyncYuQue: "/knowledge-api/channel/syncYuQue",
    },
    yuQueKnowledgeBase: {
        selectSyncList: "/knowledge-api/yuQueKnowledgeBase/selectSyncList",
        selectTreeFolderAndFile: "/knowledge-api/yuQueKnowledgeBase/selectTreeFolderAndFile",
        syncFile: "/knowledge-api/yuQueKnowledgeBase/syncFile",
        cancelSyncFile: "/knowledge-api/yuQueKnowledgeBase/cancelSyncFile",
        getSyncStatus: "/knowledge-api/yuQueKnowledgeBase/getFirstSyncStatus",
    },
    preview: {
        previewUrl: '/knowledge-api/knowledge/getTemporaryCertificate',
        previewContent: "/knowledge-api/knowledge/htmlTextDeal?onlyText=false"
    },
    department: {
        newSearchUser: "/portal-api/department/search-member-v2" //搜索员工接口
    },
    knowledge: { // 知识管理
        // searchKnowledge: '/knowledge-api/knowledge/search', // POST 首页知识列表搜索
        // deleteKnowledge: '/knowledge-api/knowledge', // DELETE 删除知识
        // changeKnowledge: '/knowledge-api/knowledge/', // PUT 更新模版
        // getAigcCmdList: '/knowledge-api/aigc-cmd/list/', // GET 获取场景指令集
        // aiLearning: '/knowledge-api/knowledge/ai-learning-progress', //POST 获取AI学习进度
        // collectKnowledge: '/knowledge-api/knowledge/collect', // PUT 收藏或者取消收藏知识
        // getBotBindInfo: '/api-open/bind/knowledge/auth', // GET 获取机器人信息
        // getTemporaryCertificate: '/knowledge-api/knowledge/getTemporaryCertificate', // 获取文件临时访问链接(POST)
        htmlTextDeal: '/knowledge-api/knowledge/htmlTextDeal', //POST 处理编辑器中插入图片和附件的oss访问地址为临时地址
        selectCorpKnowledgeBotId: '/api-open/bind/knowledge/selectCorpKnowledgeBotId', //获取知识管理后台的测试窗使用机器人ID（GET）
        corpTestWindowHistory: '/api-open/message/corpTestWindowHistory/', // 获取历史消息
        getCount: "/knowledge-api/aigc-record/count", // POST 获取次数信息
        selectUserBuyAbility: '/knowledge-api/pay/selectUserBuyAbility', // 查询用户会员信息、流量包、空间容量（GET）
        selectCompanyUseFlow: '/knowledge-api/company/selectCompanyUseFlow', // 查询询企业流量量使用情况及配置情况（GET）
        selectCompanyUseCapacity: '/knowledge-api/company/selectCompanyUseCapacity', // 查询用企业容量使用情况及配置情况（GET）
        getKnowledgeRestrict: "/knowledge-api/knowledgeBase/getKnowledgeRestrict", // GET 知识管理后台可用容量获取
        changeKnowledge: '/knowledge-api/knowledge/', // PUT 更新模版
        getAigcCmdList: '/knowledge-api/aigc-cmd/list/', // GET 获取场景指令集
        getUserHistoryCmd: '/knowledge-api/aigc-record/getUserHistoryCmd', // 获取用户历史指令（GET）
        getTemporaryCertificate: '/knowledge-api/knowledge/getTemporaryCertificate', // 获取文件临时访问链接(POST)
        getBotBindInfo: '/api-open/bind/knowledge/auth', // GET 获取机器人信息
        getDebugDataSet: "/knowledge-api/plugin-tool/debug-dataset"
    },
    dialog: {
        test: "/api/v1/dialog/test", // 测试窗问题GET
        test_v2: "/api/v2/dialog/test", // 测试窗问题GET
        test_v3: "/api-open/v3/dialog/test", // 测试窗问题POST
    },
    feishu: { // 飞书
        selectSyncList: "/knowledge-api/feishu/selectSyncList", // 获取飞书左侧知识库结构树-懒加载（GET）
        syncFile: "/knowledge-api/feishu/syncFile", // 关联同步飞书知识（POST）
        cancelSyncFile: "/knowledge-api/feishu/cancelSyncFile", // 取消关联同步飞书知识（POST）
        selectTreeFolderAndFile: "/knowledge-api/feishu/selectTreeFolderAndFile", // 查询飞书知识库结构树选中文件夹下的文件夹/文件（GET）
        syncFeishu: "/knowledge-api/channel/syncFeishu", // 单主体全量同步飞书有更新的文档
        selectSyncListWithFilter: "/knowledge-api/feishu/selectSyncListWithFilter", // 获取飞书左侧知识库结构树-懒加载（GET） 过滤了不支持的类型
    },
    company: {
        getCompanyInfo: "/portal-api/company", // 获取公司信息
        getCompanyToken: '/sso/auth-token-knowledge', // 客户端切换企业（POST）
        submitFlowApply: '/knowledge-api/company/submitApply', // 向企业提交流量/容量扩容申请（POST）
        userBigModelActivate: "/knowledge-api/company/userBigModelActivate", // 用户大模型能力激活（GET）
        getUserCompanyModule:"/portal-api/company/getUserCompanyModule", // 获取用户的系统入口 GET
    },
    docVersion: {
        selectDocumentVersionByDocId: "/knowledge-api/docVersion/selectDocumentVersionByDocId", // 查询文档的历史版本（GET）
        useDocumentVersionByDocId: "/knowledge-api/docVersion/useDocumentVersionByDocId", // 使用文档的历史版本（GET）
        selectDocumentVersionHaveNew: "/knowledge-api/docVersion/selectDocumentVersionHaveNew", // 查询文档是否有新版本（GET）
        doubleNameCheck: "/knowledge-api/knowledgeBase/doubleNameCheck", // 校验本次上传的文件是否存在重名（POST）
    },
    aigc: {
        getAiCreateContent: '/aigc/chatgpt/stream-completions', // POST 回复内容推送，EventSource流推送
        getStream: '/aigc/chatgpt/stream', // GET 订阅
        streamCompletions: '/aigc/chatgpt/stream-completions/v2', // POST 发送生成流请求
        getStreamv2: '/aigc/chatgpt/stream/v2', // GET 订阅V2
        queryUserUseAiCollaboration: "/knowledge-api/aigc-record/queryUserUseAiCollaboration", // 是否使用过AI协作能力 GET
        uploadCallback: '/knowledge-api/knowledge/upload-callback', // POST 创建二维码接口
        checkCapacity: '/knowledge-api/knowledge/check/capacity', // POST 校验容量限制
        getCapacity: "/knowledge-api/knowledge/get/capacity", // POST 获取容量信息
        getCount: "/knowledge-api/aigc-record/count", // POST 获取次数信息
        getKnowledgeSummaryNoticeList: '/knowledge-api/knowledge/handle/record/list', // 查询摘要记录 GET
        viewKnowledge: '/knowledge-api/knowledge/handle/record/view/', // 浏览摘要记录 PUT
        markKnowledgeSummary: '/knowledge-api/knowledge/handle/record/mark', // 标记摘要记录 POST
    },
    template: {
        createTemplate: "/knowledge-api/knowledge-template", // POST 创建模板
        getTemplateInfo: "/knowledge-api/knowledge-template/", // GET {id} 获取模板详情
        updateTemplate: "/knowledge-api/knowledge-template/", // PUT {id} 更新模板
        deleteTemplate: "/knowledge-api/knowledge-template/", // DELETE {id} 删除模板
        getTemplateList: "/knowledge-api/knowledge-template/page", // GET 获取模板列表
        sortTemplate: "/knowledge-api/knowledge-template/sort", // POST 模板排序
    },
    newsDirectory: {
        saveDirectory: "/knowledge-api/news-announcement/directory",//post,put,delete
        getDirectoryList: "/knowledge-api/news-announcement/directory/list",//get
        moveDirectory: "/knowledge-api/news-announcement/directory/move", //put
        loadTreeList: "/knowledge-api/news-announcement/directory/load",
        ddlTreeList: "/knowledge-api/news-announcement/directory/ddl",
        directoryDetail: "/knowledge-api/news-announcement/directory/detail/"
    },
    news: {
        newsAnnouncement: "/knowledge-api/news-announcement/", //post
        newsAnnouncementDetail: "/knowledge-api/news-announcement/detail/", //get
        publishNews: "/knowledge-api/news-announcement/publish", //put
        archiveNews: "/knowledge-api/news-announcement/archive",//put 归档
        cancelPublishNews: "/knowledge-api/news-announcement/cancel-publish",//put 取消发布
        cancelArchiveNews: "/knowledge-api/news-announcement/cancel-archive",//put 取消归档
        pinToTop: "/knowledge-api/news-announcement/pin-to-top",//put置顶
        moveNews: "/knowledge-api/news-announcement/move",//put 移动新闻
        newsList: "/knowledge-api/news-announcement/list", //post 查询新闻
        deleteNews: "/knowledge-api/news-announcement"
    },
    comment: {
        commentList: "/knowledge-api/knowledge/comment/list", //post
        saveComment: "/knowledge-api/knowledge/comment", //POST
        removeComment: "/knowledge-api/knowledge/comment/remove/", //DELETE
        replyComment: "/knowledge-api/knowledge/comment/reply", //POST
        likeComment: "/knowledge-api/knowledge/comment/like/", //POST
        likeKnowledge: "/knowledge-api/knowledge/like/", //POST
        likeKnowledgeList: "/knowledge-api/knowledge/like/",//GET
        updateReply: "/knowledge-api/knowledge/comment/update"

    },
    tagManage: {
        saveTag: "/tag-api/tag/save", //POST 保存标签
        tagList: "/tag-api/tag/list", //GET 查询标签列表
        tagTree: "/tag-api/tag/selectTree", //GET 查询子标签
        batchSave: "/tag-api/tag/batchSave", //POST 批量保存标签
        updateTag: "/tag-api/tag/",// PUT 修改标签
        deleteTag: "/tag-api/tag/",// DELETE 删除标签
        getChildrenCount: "/tag-api/tag/childrenTagCount/",//GET 获取子标签的数量
        getKnowledgeCount: "/tag-api/tag/knowledgeCount/",// GET 获取子标签下关联知识的数量
        searchTag: "/tag-api/tag/searchTag", // GET 搜索标签
        duplicateTags: "/tag-api/tag/duplicateTags", //GET 查找是否存在重复标签名
        getTagsById: "/tag-api/tag/getTagsById",//GET  根据id查询标签
        bindTag: "/tag-api/knowledge/bind/",// PUT 知识绑定标签
        unBindTag: "/tag-api/knowledge/unbind/", //PUT 知识解绑标签
        getAssociationKnowledge: "/tag-api/knowledge/getKnowledgeByTag", //GET 获取标签下的关联知识
        batchDeleteTags: "/tag-api/tag/batchDeleteTag",//POST 批量删除标签
        aiLabel: "/knowledge-api/ai-label/", //POST ai扩写标签
    },
    modelManage: {
        fetchModels: "/tag-api/model", //GET模型列表
        addModel: "/tag-api/model/", //添加模型
        updateModel: "/tag-api/model/", //更新模型信息
        deleteModel: "/tag-api/model/", //删除模型信息
        getModelInfo: "/tag-api/model/", //添加模型
        insertModelsTools:"/knowledge-api/plugins/tools/insertModelsTools"
    },
    botManage: {
        fetchBots: "/tag-api/bot", //GET机器人列表
        fetchBotsAndAgents:"/portal-api/dialog-trace/list-scope", //GET机器人列表
        addBot: "/tag-api/bot/", //添加机器人
        bindPluginTool: "/tag-api/bot/", //绑定插件
        updateBot: "/tag-api/bot/", //更新机器人信息
        updateBotSetting: "/tag-api/bot/settings", //更新机器人配置信息
        deleteBot: "/tag-api/bot/", //删除机器人信息
    },
    pluginsManage: {
        fetchPlugins: "/tag-api/plugins/", //GET单个插件信息
        fetchPluginsPrivateAvaiableWithTools: "/tag-api/plugins/private-available-with-tools",
        fetchPluginsPublicWithTools: "/tag-api/plugins/public-with-tools/",


    },
    contractTemplate:{
        fetchTemplates:"/tag-api/contract-template/",
        addTemplate:"/tag-api/contract-template/",
    },
    contractTemplateField:{
        contractTemplateField:"/tag-api/contract-field/",
        extractFieldValue: "/tag-api/contract-field/extract/"
    },
    contractExtraction:{
        fields: "/tag-api/contract-extraction/fields",
        fieldRealTime: "/tag-api/contract-extraction/fields/realtime"
    },
    chatDatabase:{
        fetchData: "/tag-api/chat-database/",
        postDatabase: "/tag-api/chat-database/",
        parseFile: "/tag-api/chat-database/parse-file/",
        createTable: "/tag-api/chat-database/create-table/",
        importData: "tag-api/chat-database/import-data/",
        getTableData: "/tag-api/chat-database/tables/",
        generateTrainingData: "/tag-api/chat-database/generate-training-data/",
        saveTrainingData: "/tag-api/chat-database/train-data/",
        generateResponse: "/tag-api/chat-database/generate-response/",

    },
    statisticsAnalysis:{
        fetchLatestStatisticsData:"/tag-api/statistics/",
        fetchPeriodStatisticsData:"/tag-api/statistics/"
    },
    schedulingManage: {
        postsChedulingsMessage: "/tag-api/scheduling/", //POST消息

    },
    pay: {
        selectCapacityPrice: '/knowledge-api/pay/selectCapacityPrice', // 查询容量包价格（GET）
        selectVipPrice: '/knowledge-api/pay/selectVipPrice', // 查询各版本会员价格（GET）
        selectFlowPrice: '/knowledge-api/pay/selectFlowPrice', // 查询流量包价格（GET）
        getWxPayCodeUrl: '/knowledge-api/pay/getWxPayCodeUrl', // 根据用户购买产品生成微信支付二维码（POST）
    },
    plugin: {
        getMyPlugin: "/tag-api/plugins/private-with-tools",
        getPublicPlugin: "/tag-api/plugins/public-with-tools",
        savePlugin: "/tag-api/plugins",
        getPluginById: "/tag-api/plugins/",
        searchPlugin: "/tag-api/plugins/findByName/",
    },
    pluginTool: {
        savePluginTool: "/tag-api/plugin-tools",
        getPluginTool: "/tag-api/plugin-tools",
        deletePluginTool: "/tag-api/plugin-tools",
        updateToolSort: "/tag-api/plugin-tools/updateToolSort",
        batchSaveTool: "/tag-api/plugin-tools/batchSave",
        translateEnName: "/tag-api/plugin-tools/translateToolName",
    },
    search: {
        getHistoryList: "/api-open/request/record/list",
        clearHistory: "/api-open/request/record/clear",
        removeHistory: "/api-open/request/record/delete/",
        getSessionList: "/api-open/session/record/page",
        saveSessionDialog: "/api-open/session/record/save",
        saveSessionName: "/api-open/session/record/update",
        deleteSessionDialog: "/api-open/session/record/delete"
    },
    model: {
        getModelList: "/tag-api/model",
        getModelById: "/tag-api/model/",
        getPrompt: "/tag-api/model/generate-prompt"
    },
    botLearning:{
        list:"/knowledge-api/llm/record/message/queryPage",
        listV2:"/portal-api/dialog-trace/page",
        ignore:'/knowledge-api/llm/record/message/skip',
        getDialogTraceDetail:"/portal-api/dialog-trace/detail/",
        recordList:"/knowledge-api/llm/train/message/queryPage",
        botDetail:"/api-open/llm/record/",
        debug:"/knowledge-api/llm/debug/getFullDebugInfo",
        relearn:"/knowledge-api/llm/record/message/train",
        adjust:"/knowledge-api/llm/record/message/adjust",
        reset:"/knowledge-api/llm/record/message/reset"
    },
    textWatermark: {
        getTextWatermark: "/knowledge-api/knowledgeBase/getPermissionInfo"
    },
    automation: {
        getNodeCheck: '/automation-api/automation/node-check',
        cancelApproval: '/knowledge-api/knowledgeBase/cancel-approval'
    },
    statistical:{
        bot:"/tag-api/statistics/botData",
        knowledge:"/tag-api/statistics/knowledgeData",
        knowledgeOverview:"/tag-api/statistics/knowledgeOvervieData",
        configuration:"/tag-api/statistics/configuration",
        companyAudit:"/tag-api/statistics/companyAudit"
    }
}

export { requestUrl }
